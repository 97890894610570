import React, { Component } from "react"
import {GeoInfo} from "."
import IceTrees, {IceTreesProps} from "./iceTrees"
import TreeGeo from "./treeGeo"
//import styles from "./styles.module.scss"


class NoTrees extends Component<IceTreesProps> {
  render() {
    return (
      <React.Fragment>
        {this.props.geoinfo ? (
      <React.Fragment>
        <TreeGeo geoinfo={this.props.geoinfo}/>
        <p>
          <b>Ítarlegri upplýsingar um hverja trjátegund</b>
        </p>
        <IceTrees geoinfo={this.props.geoinfo} treeAreaPlaces={this.props.treeAreaPlaces} />
        <p>
        Trjátegundir í biðflokki eru svæði þar skógrækt er ekki raunhæfur 
        kostur eða svæði þar sem rannsóknir vantar til þess að hægt sé að
        áætla kolefnisbindingu þeirra.
        </p>
      </React.Fragment>
        ) : (
        <p style={{ textAlign: 'center', fontSize: '20px' }}>
          Þysjaðu vel inn á kortið og veldu staðsetningu á kortinu þar sem áhugi er á að kanna
          kolefnisbindingu nýskógræktar. Vötn, jöklar og hálendi hærra en 
          400 m skilar engri niðurstöðu.
        </p>
        )}
    </React.Fragment>
    )
  }
}

export default NoTrees
