import React, { Component } from "react"
import {GeoInfo} from "."
//import styles from "./styles.module.scss"

export interface TreeGeoProps {
  geoinfo: GeoInfo;
}

class TreeGeo extends Component<TreeGeoProps> {
  render() {
    return (
      <React.Fragment>
        <p key="coordinates">
          <b>Hnit:</b>{" "}
          {this.props.geoinfo.lat.toLocaleString("de-DE", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4,
          })}
          , {this.props.geoinfo.long.toLocaleString("de-DE")}
        </p>
        <p><b>Hæðarbil:</b> {this.props.geoinfo.flokkar}</p>
        <p>
          <b>Svæði:</b> {this.props.geoinfo.svaedi.ID1} ,{" "}
          {this.props.geoinfo.svaedi.ID2} , {this.props.geoinfo.svaedi.ID3}{" "}
        </p>
        <p>
          Skógarkolefnisreiknirinn gefur einungis upp vænta kolefnisbindingu
          nýskógræktar miðað við þá staðsetningu sem valin er að hverju sinni
          (sjá landshnit í efstu línu hér fyrir ofan). Ef verið er að skoða stór
          svæði þarf að velja fleiri staðsetningar til að fá nánari upplýsingar
          um svæðið og fylgjast þá með hvort að breyting verður á vaxtarskilyrðum
          trjátegunda. T.d. geta skilyrði breyst hratt með aukinni hæð yfir sjó
          en valið hæðarbil birtist í annarri línu hér fyrir ofan.
        </p>
      </React.Fragment>
    );
  }
}

export default TreeGeo;
