import React, { Component } from "react"
import liveness_is, { Liveness } from "./liveness"
//import styles from "./styles.module.scss"

export interface MenuLink {
  link: string
  name: string
}
export interface IceTreeProps {
  name: string
  liveness?: string
  levenessless: string
  forest_area: string
  shelter: boolean
}

class IceTree extends Component<IceTreeProps> {
  render() {
    return (
      <React.Fragment>
        {this.props.liveness ? (
          <p>
            <b>{this.props.name}</b>
            <br />
            Gróskuflokkur: {liveness_is(Liveness[this.props.liveness])}
            {(this.props.shelter && this.props.liveness!=this.props.levenessless) &&
              ", án skjóls: "
            }
            {(this.props.shelter && this.props.liveness!=this.props.levenessless) &&
               liveness_is(Liveness[this.props.levenessless])
            }
            {(this.props.shelter && !this.props.levenessless) &&
              "Biðflokkur"
            }
            <br />
            Skógarsvæði: {this.props.forest_area}
            <br />
          </p>
        ) : (
          <p>
          <b>{this.props.name}</b>
          <br />
          Gróskuflokkur:  Biðflokkur
          <br />
          Skógarsvæði: {this.props.forest_area}
          <br />
        </p>
        )}
      </React.Fragment>
    )
  }
}

export default IceTree
