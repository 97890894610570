import React, { Component } from "react"
import {GeoInfo} from "."
import IceTree from "./iceTree"
import {Place} from "./liveness"
//import styles from "./styles.module.scss"

export interface TreeAreaPlaces {
  AO : string
  SG : string
  SF : string
  RL : string
  IB : string
}

export interface IceTreesProps {
  geoinfo: GeoInfo
  treeAreaPlaces: TreeAreaPlaces
}

class IceTrees extends Component<IceTreesProps> {
  render() {
    return (
      <React.Fragment>
            <IceTree
              name="Alaskaösp (AÖ)"
              liveness={this.props.geoinfo.groska.AO}
              levenessless={this.props.geoinfo.groskaMin.AO}
              forest_area={this.props.treeAreaPlaces.AO}
              shelter={this.props.geoinfo.skjol}
            />
            <IceTree
              name="Sitkagreni (SG)"
              liveness={this.props.geoinfo.groska.SG}
              levenessless={this.props.geoinfo.groskaMin.SG}
              forest_area={this.props.treeAreaPlaces.SG}
              shelter={this.props.geoinfo.skjol}
            />
            <IceTree
              name="Stafafura (SF)"
              liveness={this.props.geoinfo.groska.SF}
              levenessless={this.props.geoinfo.groskaMin.SF}
              forest_area={this.props.treeAreaPlaces.SF}
              shelter={this.props.geoinfo.skjol}
            />
            <IceTree
              name="Rússalerki (RL)"
              liveness={this.props.geoinfo.groska.RL}
              levenessless={this.props.geoinfo.groskaMin.RL}
              forest_area={this.props.treeAreaPlaces.RL}
              shelter={this.props.geoinfo.skjol}
            />
            <IceTree
              name="Ilmbjörk (IB)"
              liveness={this.props.geoinfo.groska.IB}
              levenessless={this.props.geoinfo.groskaMin.IB}
              forest_area={this.props.treeAreaPlaces.IB}
              shelter={this.props.geoinfo.skjol}
            />
    </React.Fragment>
    )
  }
}
export default IceTrees