import * as React from "react"
import { Component } from "react"
import {
  LineChart,
  CartesianGrid,
  Line,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Label,
} from "recharts"
import { GeoInfo } from "./"
import { CAge } from "./liveness"

export interface AllCXlsxAnnually {
  allCXlsxAnnually: {
    edges: CAge[]
  }
}

export interface AllCo2XlsxAnnually {
  allCo2XlsxAnnually: {
    edges: CAge[]
  }
}

export interface TreeBondingChartProps {
  geoinfo: GeoInfo
  data: AllCo2XlsxAnnually
}

export interface TreeBondingChartState {}

class TreeBondingChart extends Component<
  TreeBondingChartProps,
  TreeBondingChartState
> {
  render() {
    const d = this.props.data.allCo2XlsxAnnually.edges
    const groska = this.props.geoinfo.groska
    return (
      <LineChart
        width={800}
        height={300}
        data={d}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" tickCount={16} domain={[5, 60]} dataKey="node.age">
          <Label position="insideBottomRight" offset={-10}>
            Aldur (ár)
          </Label>
        </XAxis>
        <YAxis>
          <Label
            angle={270}
            position="left"
            style={{ textAnchor: "middle", color: "gray" }}
          >
            Árleg binding (tCO2/ha)
          </Label>
        </YAxis>
        <Tooltip
          wrapperStyle={{ opacity: 0.5 }}
          labelFormatter={function(value) {
            return `Aldur: ${value}`
          }}
          labelStyle={{ color: "gray" }}
        />
        <Legend />
        {groska.AO && (
          <Line
            name="Alaskaösp"
            type="monotone"
            dataKey={"node." + groska.AO}
            stroke="red"
            dot={false}
          />
        )}
        {groska.SG && (
          <Line
            name="Sitkagreni"
            type="monotone"
            dataKey={"node." + groska.SG}
            stroke="blue"
            dot={false}
          />
        )}
        {groska.SF && (
          <Line
            name="Stafafura"
            type="monotone"
            dataKey={"node." + groska.SF}
            stroke="green"
            dot={false}
          />
        )}
        {groska.RL && (
          <Line
            name="Rússalerki"
            type="monotone"
            dataKey={"node." + groska.RL}
            stroke="orange"
            dot={false}
          />
        )}
        {groska.IB && (
          <Line
            name="Ilmbjörk"
            type="monotone"
            dataKey={"node." + groska.IB}
            stroke="purple"
            dot={false}
          />
        )}
      </LineChart>
    )
  }
}

export default TreeBondingChart
