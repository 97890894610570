import React, { Component } from "react"
import { loadModules } from "esri-loader"
import { Liveness } from "./liveness"

export interface Svaedi {
  ID1: string
  ID2: string
  ID3: string
  ID4: number
}
export interface SkogSvaedi {
  SF?: string
  SG?: string
  AO?: string
  RL?: string
  IB?: string
}
export interface Groska {
  SF?: string
  SG?: string
  AO?: string
  RL?: string
  IB?: string
}
export interface GroskaMin {
  SF?: string
  SG?: string
  AO?: string
  RL?: string
  IB?: string
}
export interface GeoInfo {
  long?: number
  lat?: number
  flokkar?: string
  skjol: boolean
  svaedi: Svaedi
  skogsvaedi: SkogSvaedi
  groska: Groska
  groskaMin: GroskaMin
}
export interface MapProps {
  zoom: number
  long: number
  lat: number
  livenessLayerURL: string
  onMapClicked: (geoinfo: GeoInfo) => void
}

export class WebMapView extends Component<MapProps> {
  mapRef: React.RefObject<HTMLDivElement>
  view: any
  constructor(props: MapProps) {
    super(props)
    this.mapRef = React.createRef()
    this.eventHandler = this.eventHandler.bind(this)
    this.getGraphics = this.getGraphics.bind(this)
  }

  componentDidMount() {
    // lazy load the required ArcGIS API for JavaScript modules and CSS
    loadModules(
      ["esri/Map", "esri/views/MapView", "esri/layers/FeatureLayer"],
      { css: true }
    ).then(([ArcGISMap, MapView, FeatureLayer]) => {
      const livenessLayer = new FeatureLayer({
        url: this.props.livenessLayerURL,
        opacity: 0,
        outFields: ["*"],
        minScale: 2000000,
      })

      const map = new ArcGISMap({
        basemap: "topo-vector",
        layers: [livenessLayer],
      })

      this.view = new MapView({
        container: this.mapRef.current,
        map: map,
        center: [this.props.long, this.props.lat],
        zoom: this.props.zoom,
      })
      this.view.constraints = {
        minScale: 10000000, // User cannot zoom out beyond a scale of 1:500,000
        maxScale: 1000, // User can overzoom tiles
        rotationEnabled: false, // Disables map rotation
      }
    })
  }

  componentWillUnmount() {
    if (this.view) {
      // destroy the map view
      console.log("componentWillUnmount")
      this.view.container = null
    }
  }

  eventHandler(event: React.MouseEvent) {
    // the hitTest() checks to see if any graphics in the view
    // intersect the x, y coordinates of the pointer

    this.view
      .hitTest(event.nativeEvent)
      .then(this.getGraphics, function(error: any) {
        console.log(error)
      })
  }

  getGraphics(response: any) {
    //console.log("map.view.zoom ", this.view.zoom)
    let geoinfo: GeoInfo = undefined

    let r: any = undefined
    if (response.results.length) {
      //console.log("response.results ", response.results)
      r = response.results.filter(function(result) {
        return result.graphic.layer.type === "feature"
      })[0]
      if (typeof r !== "undefined") {
        //console.log("result ", r)
        const mapValues = r.graphic.attributes
        const svaedi: Svaedi = {
          ID1: mapValues.svaediID1,
          ID2: mapValues.svaediID2,
          ID3: mapValues.svaediID3,
          ID4: mapValues.svaediID4,
        }
        const skogsvaedi: SkogSvaedi = {
          SF: mapValues.svaediSF,
          SG: mapValues.svaediSG,
          AO: mapValues.svaediAO,
          RL: mapValues.svaediRL,
          IB: mapValues.svaediIB,
        }
        const groska: Groska = {
          SF: Liveness[mapValues.groskuflokkarSF],
          SG: Liveness[mapValues.groskuflokkarSG],
          AO: Liveness[mapValues.groskuflokkarAO],
          RL: Liveness[mapValues.groskuflokkarRL],
          IB: Liveness[mapValues.groskuflokkarIB],
        }

        const groskaMin: GroskaMin = {
          SF: Liveness[mapValues.skjollitidSF],
          SG: Liveness[mapValues.skjollitidSG],
          AO: Liveness[mapValues.skjollitidAO],
          RL: Liveness[mapValues.skjollitidRL],
          IB: Liveness[mapValues.skjollitidIB],
        }

        const longValue: number = r.mapPoint.longitude
        const latValue: number = r.mapPoint.latitude
        const flokkar: string = mapValues.Flokkar
        const skjol: boolean = (mapValues.skjol == 1 ) ? true : false

        geoinfo = {
          long: longValue,
          lat: latValue,
          flokkar: flokkar,
          skjol: skjol,
          svaedi: svaedi,
          skogsvaedi: skogsvaedi,
          groska: groska,
          groskaMin: groskaMin,
        }

      } else {
        //console.log("Maybe something is fucked up")
      }
    }
    this.props.onMapClicked(geoinfo)
  }
  render() {
    return (
      <div
        className="webmap"
        ref={this.mapRef}
        style={{ height: "535px" }}
        onClick={this.eventHandler}
      />
    )
  }
}

export default WebMapView
